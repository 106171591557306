





















































































































































































import coreApiClient from "@/services/apis/coreApiClient";
import Vue from "vue";
import BarChart from './BarChart.vue';
import moment from 'moment';
import userManager from "@/services/userManager";
import BlurredTenant from '@/components/BlurredTenant.vue';

export default Vue.extend({
    components: {BarChart, BlurredTenant},
    data() {
        return {
            showBlur: userManager.checkRole(["cpoAdmin", "cpoMonitor"], true) && !!userManager.getUserInfo().tenant?.setting?.expDate && userManager.getUserInfo().tenant.setting.expDate < new Date().getTime(),
            tabType: "month", // "month" or "total
            maxDate: this.calculateMaxDate(),
            minDate: this.calculateMinDate(),
            typeInTabError: "total",
            tab: 0,
            chargingStationMap: {},
            chargingStations: [],
            chargingStationsSelected: [],
            startDate: null,
            isStartDate: false,
            endDate: null,
            isEndDate: false,
            itemsType: ["consumption", "usage", "inactivity", "transaction", "amount", "stationsstatus"],
            width: 1000,
            loaded: false,
            chartData: null,
            rules: [
                value => {
                    if (value) {
                        return true
                    }

                    return 'Required.'
                },
            ],
            chargingLocations: [],
            chargingLocationsSelected: [],
            retailers: [],
            retailersSelected: [],
            chargerVendors: [],
            chargerVendorsSelected: [],
            chartDataError: null,
            sites: [],
            originalSites: [],
            siteSelected: [],
            siteArea: [],
            siteAreaSelected: [],
            categories: [{value: "charging-stations", name: "Charging Stations"}, {value: "users", name: "Users"}],
            categorySelected: 'charging-stations',
            listUser: [],
            userSelected: [],
        };
    },
    methods: {
        calculateMinDate() {
            const date = new Date();
            date.setDate(date.getDate() - 365);
            return date.toISOString().substr(0, 10);
        },
        calculateMaxDate() {
            const date = new Date();
            date.setDate(date.getDate() + 1);
            return date.toISOString().substr(0, 10);
        },
        changeType(type: string) {
            this.tabType = type
            this.loadData()
        },
        changeTab(tab: number) {
            this.tab = tab
            this.loadData()
        },
        saveStartDate(date) {
            this.$refs.refStartDate.save(date)
            this.startDate = date
        },
        saveEndDate(date, isLoad = true) {
            this.$refs.refEndDate.save(date)
            this.endDate = date
            if (isLoad) {
                this.loadData()
            }
        },
        async loadData() {
            this.loaded = false
            if (!this.startDate || !this.endDate) {
                this.saveEndDate(moment().format('YYYY-MM-DD'), false);
                this.saveStartDate(moment().subtract(1, 'years').format('YYYY-MM-DD'));
            }
            if (this.tab === 4) {
                const listChargingStationId = this.chargingStationsSelected.map((name) => this.chargingStationMap[name]);
                await this.getAmountStatic(this.startDate, this.endDate, listChargingStationId, this.userSelected, this.retailersSelected)
            } else if (this.tab === 5) {
                await this.getErrorStatistic(); /// Check tab = 5 thi call api thong ke loi
            } else {
                await this.getData(
                    this.itemsType[this.tab],
                    this.startDate,
                    this.endDate,
                    this.chargingStationsSelected,
                    this.siteAreaSelected,
                    this.siteSelected,
                );
            }
            this.loaded = true
        },
        async getAmountStatic(startDate, endDate, chargingStationsSelected, usersSelected: string[] = [], retailersSelected: string[] = []) {
            try {
                const data = await coreApiClient.call("chargingSessions", "statistics", {
                    startDateTime: startDate,
                    endDateTime: endDate,
                    chargingStationIds: chargingStationsSelected.join('|') || null,
                    userIds: usersSelected.join('|') || null,
                    retailerIds: retailersSelected.join('|') || null,
                });
                this.chartData = data
                this.loaded = true
            } catch (e) {
                console.error(e)
            }
        },
        async getData(
            type: string,
            startDate: string,
            endDate: string,
            chargingStationsSelected: string[] = [],
            organizationSiteSelected: string[] = [],
            chargingLocationSelected: string[] = [],
        ) {
            this.loaded = false
            try {
                const data = await coreApiClient.call("integrationEvServer", "statistics", {
                    type,
                    category: 'charging-stations',
                    startDateTime: moment(startDate).toISOString(),
                    endDateTime: moment(endDate).toISOString(),
                    year: -1,
                    chargingStationID: chargingStationsSelected.join('|') || null,
                    organizationSiteId: organizationSiteSelected.join('|') || null,
                    chargingLocationId: chargingLocationSelected.join('|') || null,
                });
                this.chartData = data
                this.loaded = true
            } catch (e) {
                console.error(e)
            }
        },
        async getChargingStations() {
            try {
                const userInfo = userManager.getUserInfo();
                const {items} = await coreApiClient.call(
                    "chargingStations",
                    "findAll",
                    {
                        Search: {
                            limit: 1000,
                            skip: 0,
                        },
                        payload: JSON.stringify({
                            filters: [{"key": "tenantId", "operator": "equal_to", "value": userInfo.tenant.id}]
                        }),
                    }
                );
                this.chargingStations = items.map((item) => item.uniqueId);
                this.chargingStationMap = items.reduce((acc, item) => {
                    acc[item.uniqueId] = item.id;
                    return acc;
                }, {});
            } catch (e) {
                console.error(e)
            }
        },

        /// Get data error statistics
        async getErrorStatistic() {
            this.loaded = false
            try {
                const data = await coreApiClient.call("chargingStations", "chargingStationStatusStatistics", {
                    chargingLocationIds: this.chargingLocationsSelected.join("|"),
                    retailerIds: this.retailersSelected.join("|"),
                    chargerVendorIds: this.chargerVendorsSelected.join("|"),
                });
                const dataShow = {
                    month: 1,
                }
                data.forEach((item) => {
                    dataShow[item._id] = item.count
                })
                this.chartDataError = [dataShow]

                this.loaded = true
            } catch (e) {
                console.error(e)
            }
            this.loaded = true
        },

        /// Get list Retailers
        async getRetailer() {
            try {
                const data = await coreApiClient.call("retailers", "findAll", {});
                this.retailers = data.items
            } catch (e) {
                console.error(e)
            }
        },

        /// Get list Sites Area
        async getSitesArea() {
            try {
                const {items} = await coreApiClient.call("organizationSites", "findAll", {});
                this.siteArea = items;
            } catch (e) {
                console.error(e)
            }
        },

        /// Get list Sites
        async getChargingLocations() {
            try {
                const {items} = await coreApiClient.call("chargingLocations", "findAll", {});
                this.sites = items;
                this.originalSites = items;
                this.chargingLocations = items;
            } catch (e) {
                console.error(e)
            }
        },

        /// Get list Vendors
        async getChargerVendors() {
            try {
                const data = await coreApiClient.call("chargerVendors", "findAll", {});
                this.chargerVendors = data.items
            } catch (e) {
                console.error(e)
            }
        },

        /// Get list user
        async getUsers() {
            try {
                const {items} = await coreApiClient.call("users", "findAll", {});
                this.listUser = items;
            } catch (e) {
                console.error(e)
            }
        },

        async onChangeSiteArea() {
            this.siteSelected = [];
            const sitesFilter: any[] = this.originalSites.filter((item: {
                organizationSiteId: string;
            }) => this.siteAreaSelected.includes(item.organizationSiteId)) || [];
            this.sites = sitesFilter && sitesFilter.length ? sitesFilter : this.originalSites;
            await this.loadData();
        },

        async getDataSearch() {
            await Promise.all([
                this.getRetailer(),
                this.getChargingLocations(),
                this.getChargerVendors(),
                this.getSitesArea(),
                this.getUsers(),
            ])
        },
    },
    watch: {},
    async mounted() {
        await this.getChargingStations();
        await this.getDataSearch();
        await this.getData('consumption', moment().subtract(1, 'years'), moment(), this.chargingStationsSelected, this.siteAreaSelected, this.siteSelected);
    },
    created() {
    },
    destroyed() {
    },
});


